import Csharp from "../assets/icons/csharp.svg";
import Java from "../assets/icons/java.svg";
import Python from "../assets/icons/python.svg";
import Javascript from "../assets/icons/js.svg";
import Typescript from "../assets/icons/typescript.svg";
import Nodejs from "../assets/icons/nodejs.svg";
import React from "../assets/icons/react.svg";
import Html5 from "../assets/icons/html5.svg";
import Css3 from "../assets/icons/css3.svg";
import Bootstrap from "../assets/icons/bootstrap.svg";
import Microsoftsqlserver from "../assets/icons/microsoft-sql-server.svg";
import Postgresql from "../assets/icons/postgresql.svg";
import Mongodb from "../assets/icons/mongodb.svg";
import Ubuntu from "../assets/icons/ubuntu.svg";
import Github from "../assets/icons/github.svg";
import Visualstudiocode from "../assets/icons/vs-code.svg";
import VisualStudio from "../assets/icons/visual-studio.svg";
import Postman from "../assets/icons/postman.svg";
import Netlify from "../assets/icons/netlify.svg";
import Vercel from "../assets/icons/vercel.svg";

const skillsData = [
  {
    title: "BACKEND",
    skills: [
      { icon: Csharp, text: "CSharp" },
      { icon: Java, text: "Java" },
      { icon: Python, text: "Python" },
      { icon: Nodejs, text: "NodeJS" },
    ],
  },
  {
    title: "FRONTEND",
    skills: [
      { icon: Html5, text: "Html5" },
      { icon: Css3, text: "Css3" },
      { icon: React, text: "React" },
      { icon: Bootstrap, text: "Bootstrap" },
      { icon: Javascript, text: "Javascript" },
      { icon: Typescript, text: "Typescript" },
    ],
  },
  {
    title: "DATABASE",
    skills: [
      { icon: Microsoftsqlserver, text: "Microsoft SQL Server" },
      { icon: Postgresql, text: "PostgreSQL" },
      { icon: Mongodb, text: "MongoDB" },
    ],
  },
  {
    title: "TOOLS",
    skills: [
      { icon: Ubuntu, text: "Ubuntu" },
      { icon: Github, text: "Github" },
      { icon: Visualstudiocode, text: "Visual Studio Code" },
      { icon: VisualStudio, text: "Visual Studio" },
      { icon: Postman, text: "Postman" },
      { icon: Netlify, text: "Netlify" },
      { icon: Vercel, text: "Vercel" },
    ],
  },
];
export default skillsData;

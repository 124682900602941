import React from "react";
import Typewriter from "typewriter-effect";

function TypeText() {
  return (
    <Typewriter
      options={{
        strings: [
          "Full Stack Developer",
          "Mechatronics Engineer",
          "Freelancer"
        ],
        autoStart: true,
        deleteSpeed: 30,
        loop: false,
      }}
      onInit={(typewriter) => {
        typewriter
          .typeString("Full Stack Developer")
          .pauseFor(2000)
          .start();
      }}
    />
  );
}

export default TypeText;

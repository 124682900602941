import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SocialLink from "../socialLink/SocialLink";

const Footer= React.memo(() => {
  return (
    <Container fluid className="py-3 position-relative">
      <Row>
        <Col md={4} className="text-center">
          <p className="fw-bold">© 2024 AG</p>
        </Col>
        <Col md={4} className="text-center">
          <p className="fw-bold">Designed and Developed by Atacan Güzelkaya</p>
        </Col>
        <Col md={4} className="z-1 text-center">
          <SocialLink />
        </Col>
      </Row>
    </Container>
  );
});

export default Footer;
import React from "react";
import { Card } from "react-bootstrap";

const AboutCardTwo = React.memo(() => {
  return (
    <Card style={{ border: "none", backgroundColor: "transparent" }}>
      <Card.Body>
        My field of Interest's are building new &nbsp;
        <b className="text-warning">Web Technologies and Products </b> and also
        in areas related to{" "}
        <b className="text-warning">Artificial Intelligence.</b>
        <br /> I am fluent in classics like
        <b className="text-warning"> C# and Javascript. </b>
        <br />
        Whenever possible, I also apply my passion for product development in
        <b className="text-warning"> .Net technologies and platform</b>, as well
        as
        <b className="text-warning">
          {" "}
          Modern Javascript Library and Frameworks
        </b>
        &nbsp; such as
        <b className="text-warning"> Node.js, React.js and Next.js</b>
      </Card.Body>
    </Card>
  );
});

export default AboutCardTwo;
import React from 'react';
import SkillItem from './SkillItem';

const SkillList = ({ skills, clickedSkill, handleSkillClick }) => {
  return (
    <div className="d-flex flex-wrap justify-content-center">
      {skills.map((skill, index) => (
        <SkillItem 
          key={index} 
          skill={skill} 
          isActive={clickedSkill === skill} 
          onClick={handleSkillClick} 
        />
      ))}
    </div>
  );
};

export default SkillList;
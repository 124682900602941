import {
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
  AiOutlineProduct,
} from "react-icons/ai";

const navItems = [
  { id: "home", icon: <AiOutlineHome size={25} />, text: "Home" },
  { id: "about", icon: <AiOutlineUser size={25} />, text: "About" },
  {
    id: "skills",
    icon: <AiOutlineFundProjectionScreen size={25} />,
    text: "Skills",
  },
  { id: "projects", icon: <AiOutlineProduct size={25} />, text: "Projects" },
];
export default navItems;
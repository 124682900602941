import React from 'react';
import { Card } from 'react-bootstrap';

const SkillItem = ({ skill, isActive, onClick }) => {
  return (
    <Card className="m-2 h-100 skill-card" onClick={() => onClick(skill)}>
      <Card.Img src={skill.icon} alt={skill.text} className='tech-icons' loading="lazy" />
      {isActive && (
        <div className="skill-name">{skill.text}</div>
      )}
    </Card>
  );
};

export default SkillItem;
import React from "react";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Sidebar from "./components/sidebar/Sidebar";
import Projects from "./pages/projects/Projects";
import Skills from "./pages/skills/Skills";
import StarBackground from "./components/background/StarBackground";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import ErrorBoundary from "./core/ErrorBoundary";
import "./App.css";

function App() {
  return (
    <ErrorBoundary>
      <StarBackground />
      <Sidebar />
      <Home />
      <About />
      <Skills />
      <Projects />
      <Footer />
      <ScrollToTop />
    </ErrorBoundary>
  );
}

export default App;
import React, { useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import ProjectModal from "../../components/projectModal/ProjectModal";
import projectsData from "../../data/projectsData";

const Projects = React.memo(() => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const handleShow = (project) => {
    setSelectedProject(project);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedProject(null);
  };

  return (
    <Container className="my-5 py-5" id="projects">
      <Row className="pt-3">
        <h1 className="text-center text-warning fw-bold">Projects</h1>
        {projectsData.map((project, index) => (
          <Col sm={6} className="my-4" key={index}>
            <Card className="h-100">
              <Card.Img
                variant="top"
                src={project.image}
                alt={project.title}
                loading="lazy"
              />
              <Card.Body className="d-flex flex-column">
                <Card.Title>{project.title}</Card.Title>
                <Card.Text className="flex-grow-1">
                  {project.description}
                </Card.Text>
                <div className="d-flex justify-content-between">
                  <Button
                    variant="outline-light"
                    onClick={() => handleShow(project)}
                  >
                    View Detail
                  </Button>
                  {project.viewUrl && (
                    <Button
                      variant="outline-warning"
                      href={project.viewUrl}
                      target="_blank"
                    >
                      View Demo
                    </Button>
                  )}
                  <Button
                    variant="outline-light"
                    href={project.githubUrl}
                    target="_blank"
                  >
                    View Github
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <ProjectModal
        project={selectedProject}
        showModal={showModal}
        handleClose={handleClose}
      />
    </Container>
  );
});

export default Projects;

import React, { useState, useEffect, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import SkillList from './SkillList';
import SkillNavigation from './SkillNavigation';
import './skillCard.css';

const SkillCard = ({ title, skills }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [skillsToShow, setSkillsToShow] = useState(4);
  const [clickedSkill, setClickedSkill] = useState(null);

  useEffect(() => {
    const updateSkillsToShow = () => {
      setSkillsToShow(window.innerWidth > 1440 ? 5 : 3);
    };

    updateSkillsToShow();
    window.addEventListener('resize', updateSkillsToShow);

    return () => {
      window.removeEventListener('resize', updateSkillsToShow);
    };
  }, []);

  const visibleSkills = useMemo(() => skills.slice(startIndex, startIndex + skillsToShow), [startIndex, skills, skillsToShow]);

  const handleNext = () => {
    if (startIndex + skillsToShow < skills.length) {
      setStartIndex(startIndex + skillsToShow);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - skillsToShow);
    }
  };

  const handleSkillClick = (skill) => {
    setClickedSkill(skill);
  };

  return (
    <Card className="mb-3 tech-cards">
      <Card.Body className="d-flex flex-column">
        <Card.Title className="text-center fw-bold">{title}</Card.Title>
        <SkillList 
          skills={visibleSkills} 
          clickedSkill={clickedSkill} 
          handleSkillClick={handleSkillClick} 
        />
        <SkillNavigation 
          handlePrev={handlePrev} 
          handleNext={handleNext} 
          disablePrev={startIndex === 0} 
          disableNext={startIndex + skillsToShow >= skills.length} 
        />
      </Card.Body>
    </Card>
  );
};

export default SkillCard;
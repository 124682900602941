const ParticleOptions = {
  autoPlay: true,
  background: {
    image: '',
    position: '',
    repeat: '',
    size: '',
    opacity: 1,
  },
  backgroundMask: {
    composite: 'destination-out',
    cover: {
      color: {
        value: '#fff',
      },
      opacity: 1,
    },
    enable: false,
  },
  clear: true,
  delay: 0,
  fullScreen: {
    enable: true,
    zIndex: -1,
  },
  detectRetina: true,
  fpsLimit: 120,
  interactivity: {
    detectsOn: 'window',
    events: {
      onClick: {
        enable: false,
      },
      onDiv: {
        selectors: [],
        enable: false,
      },
      onHover: {
        enable: false,
        parallax: {
          enable: false,
        },
      },
      resize: {
        delay: 0.5,
        enable: true,
      },
    },
    modes: {
      push: {
        default: true,
        quantity: 4,
      },
      remove: {
        quantity: 2,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
    },
  },
  particles: {
    collisions: {
      enable: false,
      maxSpeed: 50,
    },
    color: {
      value: '#fff',
    },
    move: {
      enable: false,
      speed: 2,
    },
    number: {
      density: {
        enable: true,
        value_area: 800,
      },
      value: 200,
    },
    opacity: {
      value: {
        min: 0.1,
        max: 1,
      },
      animation: {
        enable: true,
        speed: 0.25,
      },
    },
    shape: {
      type: 'circle',
    },
    size: {
      value: 1,
    },
  },
  pauseOnBlur: true,
  pauseOnOutsideViewport: true,
  zLayers: 100,
};

export default ParticleOptions;
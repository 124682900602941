import React, { useEffect, useState, memo, useCallback } from 'react';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import ParticleOptions from './ParticleOptions';

const StarBackground = memo(() => {
  const [init, setInit] = useState(false);

  const initializeParticles = useCallback(async () => {
    try {
      await initParticlesEngine(async (engine) => {
        await loadSlim(engine);
      });
      setInit(true);
    } catch (error) {
      console.error("Error initializing particles:", error);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;

    const initParticles = async () => {
      await initializeParticles();
    };

    if (isMounted) {
      initParticles();
    }

    return () => {
      isMounted = false;
    };
  }, [initializeParticles]);

  if (!init) {
    return null;
  }

  return <Particles id="tsparticles" options={ParticleOptions} />;
});

export default StarBackground;
import React from "react";
import { Card } from "react-bootstrap";

const content = {
  name: "Atacan Güzelkaya",
  location: "Istanbul, Türkiye",
  degree: "Mechatronics Engineering",
  activities: "researching, developing and fixing",
  jobTitle: "Full Stack Developer",
  university: "Istanbul Bilgi University",
  additionalActivity: "playing games",
};

const AboutCardOne = React.memo(() => {
  return (
    <Card style={{ border: "none", backgroundColor: "transparent" }}>
      <Card.Body>
        Hi Everyone, I am <b className="text-warning">{content.name}</b> from{" "}
        <b className="text-warning">{content.location}</b>.
        <br />I am currently looking for a job as a{" "}
        <b className="text-warning">{content.jobTitle}</b>.
        <br />
        In 2022, I graduated from {content.university} with a bachelor's in{" "}
        <b className="text-warning">{content.degree}</b>.
        <br />I enjoy <b className="text-warning">{content.activities}</b>.
        <br />
        I like to improve myself and the projects I do.
        <br />
        Another activity I like to do besides coding is{" "}
        {content.additionalActivity}.
      </Card.Body>
    </Card>
  );
});

export default AboutCardOne;
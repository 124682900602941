import { useState, useEffect, useCallback, useMemo } from "react";
import { RiSpaceShipFill } from "react-icons/ri";

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 500) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const backToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (window.location.hash) {
      window.history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    }
  }, []);

  const style = useMemo(
    () => ({
      height: 45,
      width: 45,
      borderRadius: 50,
      position: "fixed",
      right: 50,
      bottom: 50,
      display: showScroll ? "flex" : "none",
      padding: 5,
      cursor: "pointer",
    }),
    [showScroll]
  );

  return (
    <RiSpaceShipFill
      onClick={backToTop}
      className="text-warning"
      style={style}
    />
  );
};

export default ScrollToTop;
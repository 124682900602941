import { AiFillGithub, AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

const socialLinks = [
  {
    name: "GitHub",
    icon: <AiFillGithub />,
    url: "https://github.com/atacanguzelkaya",
  },
  {
    name: "LinkedIn",
    icon: <FaLinkedinIn />,
    url: "https://www.linkedin.com/in/atacan-guzelkaya",
  },
  {
    name: "Instagram",
    icon: <AiFillInstagram />,
    url: "https://www.instagram.com/atacanguzelkaya",
  },
];

export default socialLinks;
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ProjectModal = ({ project, showModal, handleClose }) => (
  <Modal show={showModal} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>{project?.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <img src={project?.image} alt={project?.title} className="img-fluid" loading='lazy'/>
      <p className='mt-3'>{project?.description}</p>
      <p className='mt-3'>{project?.details}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button variant="warning" href={project?.githubUrl} target='_blank'>
        View Github
      </Button>
    </Modal.Footer>
  </Modal>
);
export default ProjectModal;
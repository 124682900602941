import React from "react";
import {Container,Row, Col} from "react-bootstrap";
import SkillCard from "../skillCard/SkillCard";
import skillsData from "../../data/skillsData";

const Techstack = () => {
  return (
    <Container className="mt-4">
      <Row>
        {skillsData.map((category) => (
          <Col md={6} key={category.title}>
            <SkillCard title={category.title} skills={category.skills} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Techstack;
import React from 'react';
import { Button } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const SkillNavigation = ({ handlePrev, handleNext, disablePrev, disableNext }) => {
  return (
    <div className="d-flex justify-content-between mt-3">
      <Button
        variant="outline-warning"
        onClick={handlePrev}
        disabled={disablePrev}
        aria-label="Previous Skills"
      >
        <FaArrowLeft />
      </Button>
      <Button
        variant="outline-warning"
        onClick={handleNext}
        disabled={disableNext}
        aria-label="Next Skills"
      >
        <FaArrowRight />
      </Button>
    </div>
  );
};

export default SkillNavigation;
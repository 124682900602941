import React, { Suspense } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import myImg from "../../assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import TypeText from "../../components/typeText/TypeText";
import pdf from "../../assets/Atacan_Guzelkaya.pdf";
import SocialLink from "../../components/socialLink/SocialLink";
const FiDownload = React.lazy(() => import("react-icons/fi").then(module => ({ default: module.FiDownload })));

const Home = React.memo(() => {
  return (
    <Container className="my-5 py-5" id="home">
      <Row className="pt-3">
        <Col md={6} className="text-center">
          <h1 className="px-5">Hi There! </h1>

          <h1 className="px-5">
            I'M
            <strong className="text-warning"> ATACAN GÜZELKAYA</strong>
          </h1>

          <h1 className="px-5 py-4">
            <strong className="text-warning">
              <TypeText />
            </strong>
          </h1>
          
          <Suspense>
            <a
              href={pdf}
              download="Atacan_Guzelkaya.pdf"
              className="btn btn-outline-warning mx-5 my-4 rounded-5"
            >
              Resume <FiDownload className="mx-2" />
            </a>
          </Suspense>
        </Col>

        <Col md={6} className="text-center">
          <Tilt>
            <Image src={myImg} className="img-fluid" alt="avatar" />
          </Tilt>
          <SocialLink className="my-3"/>
        </Col>
      </Row>
    </Container>
  );
});

export default Home;
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import AboutCardTwo from "../../components/aboutCard/AboutCardTwo";
import AboutCardOne from "../../components/aboutCard/AboutCardOne";

const About = React.memo(() => {
  return (
    <Container className="my-5 pt-5" id="about">
      <Row className="pt-3">
      <h1 className="text-center">
        WHO <strong className="text-warning">AM I</strong> ?
      </h1>
        <Col md={6} xs={12} >
          <AboutCardOne />
        </Col>
        <Col md={6} xs={12} >
          <AboutCardTwo />
        </Col>
      </Row>
    </Container>
  );
});

export default About;

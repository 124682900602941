import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import navItems from '../../data/navItems';
import './Sidebar.css';

const Sidebar = () => {
  return (
    <Navbar fixed="top" className="justify-content-center">
      <Nav className="flex-row">
        {navItems.map(item => (
          <Nav.Item key={item.id}>
            <Nav.Link href={`#${item.id}`} className="icon-link" aria-label={item.text}>
              {item.icon}
              <span className="icon-text">{item.text}</span>
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </Navbar>
  );
};

export default Sidebar;
import React from 'react';
import { Link } from 'react-router-dom';
import socialLinksData from '../../data/socialLinksData';
import { memo } from 'react';

const iconSize = 30;
const iconColor = "white";

const SocialLink = () => {
  return (
    <ul className="list-unstyled d-flex justify-content-center">
      {socialLinksData.map((link) => (
        <li key={link.url} className="d-inline-block px-3">
          <Link
            to={link.url}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={link.name}
          >
            {React.cloneElement(link.icon, { size: iconSize, color: iconColor })}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default memo(SocialLink);
import React, {  useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import TagCloud from 'TagCloud';
import './WordCloud.css';

const WordCloud = () => {
  const container = '.tagcloud';
  const texts = [
    'Java',
    'C#',
    'Python',
    'JavaScript',
    'TypeScript',
    'HTML5',
    'CSS3',
    'NodeJS',
    'React',
    'Redux',
    'MsSQL',
    'PostgreSQL',
    'Mongodb',
    'Ubuntu',
    'Github',
    'Postman',
  ];
  const getRadius = () => {
    const width = window.innerWidth;
    return width < 768 ? 150 : width < 1200 ? 250 : 300;
  };
  const options = {
    radius: getRadius(),
    maxSpeed: 'fast',
    initSpeed: 'fast',
    direction: 135,
    keep: true,
  };

  useEffect(() => {TagCloud(container, texts, options)})

  return (
    <Container>
      <Row>
        <Col className="text-center">
          <div className="tagcloud"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default WordCloud;
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import WordCloud from '../../components/wordCloud/WordCloud';
import Techstack from '../../components/techStack/Techstack';

const Skills = React.memo(() => {
  return (
    <Container className="my-5 py-5" id='skills'>
      <Row className="pt-3">
        <h1 className="text-center">
          Professional <strong className="text-warning">Skillset </strong>
        </h1>
        <Col className="tagcloud-wrap">
          <WordCloud />
        </Col>
        <Techstack/>
      </Row>
    </Container>
  )
});

export default Skills;